// src/pages/AboutUs.tsx
import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    Flex,
    VStack,
    HStack,
    Icon,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { FaHammer, FaHome, FaTools, FaUserTie } from 'react-icons/fa';

export const AboutUs = () => {
    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={10}>
                <VStack spacing={10} align="stretch">
                    <Box>
                        <Heading as="h1" size="2xl" mb={4}>About SJT Constructions</Heading>
                        <Text fontSize="xl">Building Queensland's Future, One Project at a Time</Text>
                    </Box>

                    <Flex direction={{ base: "column", md: "row" }} gap={8}>
                        <Image
                            src="/product_images/sam.png" // Replace with actual image of Sam Toby
                            alt="Sam Toby"
                            borderRadius="md"
                            objectFit="cover"
                            maxW={{ base: "100%", md: "400px" }}
                        />
                        <VStack align="start" spacing={4}>
                            <Heading as="h2" size="xl">Meet Sam Tovey</Heading>
                            <Text>
                                With over 15 years of experience in carpentry and building, Sam Toby is the heart and soul of SJT Constructions. Sam's passion for craftsmanship and attention to detail runs in his blood, having learned the trade from his father, a master carpenter with a lifetime of experience.
                            </Text>
                            <Text>
                                Carrying on the family tradition, Sam has built SJT Constructions into a reputable and trusted name in the Queensland construction industry. His dedication to quality and customer satisfaction has earned him a loyal clientele and a strong reputation in the community.
                            </Text>
                        </VStack>
                    </Flex>

                    <Box>
                        <Heading as="h3" size="lg" mb={4}>Our Expertise</Heading>
                        <Text mb={4}>
                            At SJT Constructions, we take pride in our versatility and expertise. Whether it's a new Queensland project, an extension, or a renovation, Sam and his team are always excited to sink their teeth into a new challenge. Our services include:
                        </Text>
                        <HStack spacing={8} flexWrap="wrap">
                            {["Kitchens", "Verandas", "Bedrooms", "Carports", "Bathrooms", "Complete Home Renovations"].map((service) => (
                                <HStack key={service}>
                                    <Icon as={FaHammer} color="blue.500" />
                                    <Text fontWeight="bold">{service}</Text>
                                </HStack>
                            ))}
                        </HStack>
                    </Box>

                    <Box>
                        <Heading as="h3" size="lg" mb={4}>Our Commitment</Heading>
                        <Text>
                            Sam's passion for perfection is evident in every project SJT Constructions undertakes. We believe in not just meeting but exceeding our clients' expectations. Our commitment to quality craftsmanship, timely delivery, and clear communication sets us apart in the industry.
                        </Text>
                    </Box>

                    <Box>
                        <Heading as="h3" size="lg" mb={4}>Community Focus</Heading>
                        <Text>
                            As a local Queensland business, we're deeply invested in our community. We understand the unique challenges and opportunities of building in this beautiful region, and we take pride in contributing to its growth and development through our work.
                        </Text>
                    </Box>
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Flex>
    );
};

export default AboutUs;